<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-swap-horizontal</v-icon>
          CAMBIAR CARGA DE GUIA MASTER
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-col cols="4">
          <v-autocomplete
            label="Guia de origen"
            :items="guiasCoord"
            item-value="CABECERA_ID"
            item-text="AWB"
            v-model="guiaOrigenId"
            @change="cargarDetalleGuiaOrigen()"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.CLIENTE_NOMBRE"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.FECHA"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-html="data.item.AWB"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-btn small color="primary" small @click="abrirCambioGuia"
            ><v-icon>mdi-text-search</v-icon>cambiar a la guía</v-btn
          >
        </v-col>
      </v-toolbar>

      <v-data-table
        :headers="headDetalleOrigen"
        :options.sync="options"
        :items="detalleOrigen"
        :itemsPerPage="itemsPerPage"
        :server-items-length="totalItems"
        class="elevation-1"
        show-select
        :single-select="false"
        v-model="seleccion"
        item-key="DETALLE_ID"
        hide-default-footer
      >
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- DIALOG CAMBIO DE GUIA -->
    <v-dialog v-model="dialogCambioGuia" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-check</v-icon>
          <span class="headline">Cambiar a la guia:</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formConfReserva">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    v-model="guiaSelId"
                    small
                    item-text="AWB"
                    item-value="CABECERA_ID"
                    :items="guiasAbiertas"
                    clearable
                    label="Seleccione una guia"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.CLIENTE_NOMBRE"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.FECHA"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="data.item.AWB"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-btn
                    :loading="btnTransLoading"
                    color="primary"
                    small
                    @click="cambiarAguia"
                  >
                    <v-icon>mdi-check</v-icon>
                    Aceptar
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :loading="btnTransLoading"
                    color="primary"
                    small
                    text
                    @click="dialogCambioGuia = false"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ComponentName",
  components: {},
  props: {},
  data: () => ({
    headDetalleOrigen: [
      { text: "ID", value: "DETALLE_ID" },
      { text: "Finca", value: "FINCA_NOMBRE" },
      { text: "Cliente", value: "SCLIENTE_NOMBRE" },
      { text: "Hawb", value: "NUM_GUIA_FINCA" },
      { text: "Cajas coordinadas", value: "CAJAS_CONF" },
    ],
    options: {},
    overlay: false,
    datosForm: {
      ID: "",
      NOMBRE: "",
      DESCRIPCION: "",
      ESTADO: "",
    },
    detalleOrigen: [],
    listaAutocomplete: [],
    hcoordActiva: [],
    guiasCoord: [],
    guiasAbiertas: [],
    seleccion: [],
    guiaOrigenId: "",
    itemsPerPage: 50,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    dialogCambioGuia: false,
    guiaSelId: "",
    btnTransLoading: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/guias-hcoord");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.hcoordActiva = res.data.hcoord;
          this.guiasCoord = res.data.guias;
          this.guiasAbiertas = res.data.guias;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarDetalleGuiaOrigen() {
      this.setLoadingTable(true);
      this.setUrl("api/detalle-guia");

      this.requestApi({
        method: "GET",
        data: { CABECERA_ID: this.guiaOrigenId },
      })
        .then((res) => {
          console.log(res.data);
          this.detalleOrigen = res.data.lista;
          this.totalItems = res.data.totalItems;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cambiarAguia() {
      if (this.guiaOrigenId == this.guiaSelId) {
        this.alertNotification({
          param: {
            html: "La guia de destino no puede ser igual a la de orígen",
            timer: 0,
            icon: "error",
            toast: false,
            title: "Error",
          },
        });

        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("api/cambiar-carga-guia");
      this.requestApi({
        method: "POST",
        data: {
          cabeceraOrigenId: this.guiaOrigenId,
          cabeceraDestId: this.guiaSelId,
          detallesId: this.seleccion,
        },
      })
        .then((res) => {
          this.seleccion = [];
          this.cabeceraDestId = "";
          this.dialogCambioGuia = false;

          this.setLoadingTable(false);
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 5000,
              icon: "success",
              toast: true,
              title: "Exito",
            },
          });
          this.cargarDetalleGuiaOrigen();
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirCambioGuia() {
      if (this.seleccion.length == 0) {
        this.alertNotification({
          param: {
            html: "Seleccione al menos una guía",
            timer: 0,
            icon: "error",
            toast: false,
            title: "Error",
          },
        });
        return false;
      }
      this.dialogCambioGuia = true;
    },
    handlePageChange() {
      this.cargarLista();
    },
  },
  mounted() {
    this.cargarDatos();
  },
};
</script>
